:root {
  --grey-lightest: #f7f9fa;
  --white: #ffffff;
  --grey: #c8d1dc;
  --dark-grey: #6b7785;
  --dark-blue: #1f2d3d;
  --dark-blue-border: #2b3f56;
  --darkest-blue: #29313b;
  --green: #72cc18;
  --red-dark: #f63135;
  --turquoise: #1bebb9;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
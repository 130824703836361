.page-container {
    display: flex;
    justify-content: space-between;
    width: 25%;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    height: 92%;
}

.right-cont {
    flex: 1;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@media (max-width: 900px) {
    .page-container {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .page-container {
        width: 100%;
    }
}

.head-title {
    font-weight: bold;
    color: #393e46;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #393e46;
}

.chats:hover,
.attendies:hover {
    background-color: #f8f8f8;
    cursor: pointer;
}

.chats,
.attendies {
    padding: 10px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    transition: background-color 0.5s;
}

.attendies-detail {
    padding: 10px;
    width: 100%;
    transition: background-color 0.5s;
}

.chat-cont {
    background-color: #ffffff;
    flex: 1;
    padding: 10px;
    padding-top: 10px;
    overflow-x: hidden;
}

.chat-input-cont {
    padding: 12px;
    display: flex;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
}

.ci-cont {
    width: 350%;
}

.ci-send {
    width: 20%;
    margin-left: 20px;
}

.chat-input {
    width: 100%;
    height: 36px;
    font-size: 0.85rem;
    padding: 4px 10px;
    border: none;
    border-bottom: 3px solid #4ecca3;
    color: #393e46;
}

.chat-border {
    border-bottom: 5px solid #4ecca3;
}

.chat-input:focus {
    outline: none;
}

.chat-send {
    height: 36px;
    padding: 5px 20px;
    border: none;
    background-color: white;
    color: #4ecca3;
    font-weight: bold;
    font-size: 0.85rem;
    border-radius: 2px;
    transition: background-color 0.5s;
}

.chat-send:hover {
    color: #2980b9;
    cursor: pointer;
    background-color: #f3f3f3;
}

.chat-send:focus {
    outline: none;
}

.message {
    margin-top: 10px;
}

.left-side {
    flex: 2.7;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.left-button-audio {
    background-color: #4ecca3;
    text-align: center;
    color: white;
    padding: 1px;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    transition: background-color 0.3s;
}

.left-button-audio:hover {
    cursor: pointer;
    background-color: #393e46;
}

.left-button-video {
    background-color: #4ecca3;
    text-align: center;
    margin-left: 10px;
    color: white;
    padding: 1px;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    transition: background-color 0.3s;
}

.left-button-video:hover {
    cursor: pointer;
    background-color: #393e46;
}

.left-button-present {
    background-color: #d8d8d8;
    text-align: center;
    margin-left: 10px;
    color: #393e46;
    padding: 1px;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    transition: background-color 0.3s;
    transition: color 0.3s;
}

.left-button-present:hover {
    cursor: pointer;
    color: white;
    background-color: #393e46;
}


.left-button-endCall {
    background-color: #cc4e4e;
    text-align: center;
    margin-left: 10px;
    color: white;
    padding: 1px;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    transition: background-color 0.3s;
}

.left-button-endCall:hover {
    cursor: pointer;
    background-color: #393e46;
}

.utils {
    text-align: center;
    margin: auto;
    display: flex;
    margin-bottom: 10px;
    width: 360px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
}

.left-button-audio,
.left-button-video,
.left-button-present,
.left-button-endCall {
    margin: 4px 18px;
}

@media (max-width: 1108px) {
    .utils {
        text-align: center;
        margin: auto;
        display: flex;
        margin-bottom: 10px;
        width: 300px;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 10px;
        justify-content: center;
    }

    .left-button-audio,
    .left-button-video,
    .left-button-present,
    .left-button-endCall {
        margin: 2px 14px;
    }
}
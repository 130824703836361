.main {
  display: flex;
  padding: 0 37%;
  width: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

video::-webkit-media-controls {
  display: none;
}

@media only screen and (max-width: 1050px) {
  .main {
    padding: 0 30%;
  }
}

@media only screen and (max-width: 900px) {
  .main {
    padding: 0 27%;
  }
}

@media only screen and (max-width: 800px) {
  .main {
    padding: 0 25%;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    padding: 0 20%;
  }
}

@media only screen and (max-width: 450px) {
  .main {
    padding: 0 15%;
  }
}

.main .create-join {
  width: 100%;
}

.main .create-join .text .head {
  font-size: 36px;
  font-weight: bold;
  color: #393e46;
}

.main .create-join .text .subtext {
  font-size: 24px;
  color: #6f6f6f;
  margin-top: 10px;
}

.main .create-join .createroom-butt {
  width: 100%;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: #4ecca3;
  color: white;
  text-align: center;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  border: 2px solid #4ecca3;
  transition: 0.3s;
}

.heading {
  font-size: 32px;
  margin: 72px 0 48px;
  font-family: sans-serif;
  font-weight: bold;
}

.main .create-join .createroom-butt:hover {
  background-color: white;
  border: 2px solid #4ecca3;
  color: #4ecca3;
  cursor: pointer;
}

.main .create-join .createroom-butt:focus {
  outline: none;
}

.main .create-join .createroom-clicked {
  width: 100%;
  margin-top: 60px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: white;
  border: 2px solid #4ecca3;
  color: #4ecca3;
}

.main .create-join .createroom-clicked:focus {
  outline: none;
}

.main .create-join .roomcode {
  width: 100%;
  margin-top: 30px;
  font-size: 24px;
  color: #393e46;
  text-align: center;
  padding: 10px 0;
  border: none;
  border-bottom: 3px solid #232931;
  border-top: 2px solid white;
  font-weight: bold;
}

.main .create-join .roomcode:focus {
  background-color: white;
  border: 0;
  border-bottom: 3px solid #232931;
  border-top: 2px solid white;
  outline: none;
  border-radius: 0;
}

.main .create-join .roomcode-error {
  border-bottom: 3px solid #d31c1c;
}

.main .create-join .joinroom {
  margin-top: 20px;
  width: 100%;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  color: #232931;
}

.main .create-join .joinroom:hover {
  cursor: pointer;
  text-decoration: underline;
}

.main .video-cont {
  width: 100%;
  text-align: center;
}

.main .video-cont .video-self {
  height: 400px;
  width: 100%;
  background-color: #393e46;
  border-radius: 10px;
  object-fit: cover;
}

.main .video-cont .settings {
  margin: 0 auto;
  display: flex;
  margin-top: 10px;
  width: 100px;
}

.main .video-cont .settings .device {
  background-color: #4ecca3;
  border-radius: 100px;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
}

.main .video-cont .settings .device:hover {
  background-color: #393e46;
  cursor: pointer;
}

.main .video-cont .settings .nodevice {
  background-color: #b12c2c;
  border-radius: 100px;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: white;
}

.main .video-cont .settings .nodevice:hover {
  background-color: #393e46;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .main {
    flex-direction: column;
  }

  .main .create-join {
    width: 100%;
    margin-right: 0;
  }

  .main .video-cont {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.room {
  background-color: var(--darkest-blue);
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr 4rem;
  /* padding-top: 2rem; */
}

.nameTag {
  position: absolute;
  z-index: 2;
  color: var(--grey);
  background-color: var(--dark-blue);
  bottom: 0;
  left: 0;
  font-size: 14px;
  padding: 0.2em 0.5em;
}

.mute-icon {
  position: absolute;
  z-index: 2;
  color: var(--grey);
  background-color: var(--dark-blue);
  bottom: 0;
  right: 0;
  font-size: 14px;
  padding: 0.2em 0.5em;
}

.video-off {
  position: absolute;
  z-index: 2;
  color: var(--grey);
  background-color: var(--dark-blue);
  font-size: 14px;
  padding: 0.2em 0.5em;
  left: 0;
  top: 0;
}

#localVideo {
  position: relative;
}

.videoParent {
  position: relative;
}

.call {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
  position: relative;
}

.tile-video {
  position: relative;
  /* width: 480px;
  height: 270px; */
}

/* @media only screen and (max-width: 600px) {
  .tile-video {
    width: 240px;
    height: 135px;
  }
} */

/* .self-view {
  width: 480px;
  height: 270px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .self-view {
    width: 240px;
    height: 135px;
  }
} */

.no-video {
  background-color: var(--dark-blue-border);
}

.no-video video {
  visibility: hidden;
}

/* Hard-code the self-view size if there's no one else in the call, just to make it align with the info-box */
@media only screen and (max-width: 600px) {
  .tile-screenshare {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
}

/* Put screen share front and center in our grid when there is one active */
.tile-screenshare {
  grid-column: 1 / -1;
  grid-row: 1;
  position: relative;
}

.screenshare {
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 15px;
}